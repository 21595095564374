import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage/plugin-tech-radar';

  import data from '../data/mbm-tech-radar.json';
  
  export class MBMTechRadar implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
      // For example, this converts the timeline dates into date objects
      console.log('async load of tech radar with id: ' + id);
      return {
        ...data,
        entries: data.entries.map(entry => ({
          ...entry,
          timeline: entry.timeline.map(timeline => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }
  